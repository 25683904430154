import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const FLY_URL = process.env.REACT_APP_FLY_URL;
  const [user, setUser] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  // Función para iniciar sesión
  const loginUser = async (email, password) => {
    try {
      const response = await axios.post(
        `${FLY_URL}api/login`,
        { email, password },
        { withCredentials: true }
      );
      setUser(response.data.user);
      setAlertMessage('Inicio de sesión exitoso');
      navigate('/');
    } catch (error) {
      console.error('Error en la autenticación:', error);
      setAlertMessage(error.response?.data?.message || 'Error en la autenticación');
    }
  };

  // Función para registrar un usuario
  const registerUser = async (name, lastname, email, password) => {
    try {
      await axios.post(
        `${FLY_URL}api/register`,
        { name, lastname, email, password },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setAlertMessage('Usuario registrado correctamente. Ahora puede iniciar sesión.');
      navigate('/auth');
    } catch (error) {
      console.error('Error en el registro:', error);
      setAlertMessage(error.response?.data?.message || 'Error en el registro');
    }
  };

  const updateUser = async (name, lastname, email) => {
    try {
      await axios.put(
        `${FLY_URL}api/user`,
        { name, lastname },
        { withCredentials: true }
      );

      setAlertMessage('Usuario actualizado correctamente');
      fetchUser(); // Actualiza el estado del usuario
    } catch (error) {
      console.error('Error al actualizar usuario:', error);
      setAlertMessage(error.response?.data?.message || 'Error al actualizar usuario');
      throw error;
    }
  };


  // Función para cerrar sesión
  const handleLogout = useCallback(async () => {
    try {
      await axios.post(`${FLY_URL}api/logout`, {}, { withCredentials: true });
      setUser(null);
      navigate('/auth');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  }, [navigate, FLY_URL]);

  // Función para obtener el usuario
  const fetchUser = useCallback(async () => {
    try {
      const response = await axios.get(`${FLY_URL}api/user`, { withCredentials: true });
      setUser(response.data.user);
    } catch (error) {
      console.error('Error al obtener el usuario:', error);
    }
  }, [FLY_URL]);

  // Ejecutar fetchUser solo una vez al montar el componente
  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <AuthContext.Provider value={{ user, alertMessage, loginUser, registerUser, handleLogout, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);