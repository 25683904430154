// IntervalContext.js (separando estados y actualizaciones)
import React, { createContext, useContext, useState, useMemo } from 'react';

const IntervalStateContext = createContext();
const IntervalUpdateContext = createContext();

export const IntervalProvider = ({ children }) => {
  
  const [selectedInterval, setSelectedInterval] = useState({ label: '5 minutos', value: '5min' });

  const stateValue = useMemo(() => ({ selectedInterval }), [selectedInterval]);
  const updateValue = useMemo(() => ({ setSelectedInterval }), []);

  return (
    <IntervalStateContext.Provider value={stateValue}>
      <IntervalUpdateContext.Provider value={updateValue}>
        {children}
      </IntervalUpdateContext.Provider>
    </IntervalStateContext.Provider>
  );
};

export const useInterval = () => useContext(IntervalStateContext);
export const useIntervalUpdate = () => useContext(IntervalUpdateContext);