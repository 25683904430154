import React, { useState } from 'react';
import MarketDataTable from './MarketDataTable';
import TradesTable from './TradesTable';
import './tablas.css';
import { useSymbol } from '../context/SymbolContext';
import { useFullscreenState } from '../context/FullScreenContext';

const TablesContainer = React.memo(() => {

  const { symbol } = useSymbol();
  const { isFullscreen } = useFullscreenState();

  const [showTables, setShowTables] = useState(true);

  if (isFullscreen) {
    return null;
  }

  return (
    <div className="tables-wrapper">
      {/* Botón de toggle */}
      <div
        className={`toggle-tables-btn ${!showTables ? 'toggle-show-tables left-arrow' : 'toggle-hide-tables right-arrow'}`}
        onClick={() => setShowTables(!showTables)}
      />
      <div className={`tables-container${showTables ? '' : '-hidden'}`}>
        <h6 className="table-title">{symbol}</h6>
        <MarketDataTable />
        <TradesTable />
      </div>
    </div>
  );
});

export default React.memo(TablesContainer);