import React, { createContext, useState, useMemo, useContext } from 'react';

const FullscreenStateContext = createContext();
const FullscreenSetContext = createContext();

export const FullscreenProvider = ({ children }) => {
  
  const [isFullscreen, setIsFullscreen] = useState(false);

  const stateValue = useMemo(() => ({ isFullscreen }), [isFullscreen]);
  const setValue = useMemo(() => ({ setIsFullscreen }), []);

  return (
    <FullscreenStateContext.Provider value={stateValue}>
      <FullscreenSetContext.Provider value={setValue}>
        {children}
      </FullscreenSetContext.Provider>
    </FullscreenStateContext.Provider>
  );
};

export const useFullscreenState = () => useContext(FullscreenStateContext);
export const useFullscreenSet = () => useContext(FullscreenSetContext);