import React, { createContext, useContext, useRef, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { useMarketDataSet } from './MarketdataContext';
import { useTodayTradesSet } from './TodayTradesContext';
import { useSymbolUpdates } from './SymbolContext';
import { useIndexedDB } from './IndexedDBContext'; // Importar el contexto de IndexedDB

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const { user } = useAuth();
  const { setMarketData } = useMarketDataSet();
  const { setTodayTrades } = useTodayTradesSet();
  const { setSymbolList } = useSymbolUpdates();
  const { storeDataInIndexedDB } = useIndexedDB(); // Usar la función del contexto de IndexedDB
  const ws = useRef(null);

  const [connected, setConnected] = useState(false);

  // Función para conectar al WebSocket
  const connectWebSocket = () => {
    if (ws.current) return; // Evitar múltiples conexiones

    ws.current = new WebSocket(process.env.REACT_APP_FLY_URL_WB);

    ws.current.onopen = () => {
      console.log('WebSocket conectado');
      setConnected(true);
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      handleWebSocketMessage(message);
    };

    ws.current.onclose = () => {
      console.log('WebSocket desconectado');
      ws.current = null; // Reiniciar referencia
      setConnected(false);
      setTimeout(connectWebSocket, 5000); // Reintentar conexión
    };

    ws.current.onerror = (error) => {
      console.error('Error en WebSocket:', error);
    };
  };

  // Función para manejar mensajes del WebSocket
  const handleWebSocketMessage = async (message) => {
    switch (message.type) {
      case 'ohlc_data':
        // Guardar datos en IndexedDB usando el contexto
        const storeName = `data_${message.interval}`;
        await storeDataInIndexedDB(storeName, message.symbol, message.data);
        break;
      case 'market_data':
        setMarketData(message.data); // Actualiza MarketData usando la función de updates
        break;
      case 'today_trades':
        setTodayTrades(message.data); // Actualiza TodayData usando la función de updates
        break;
      case 'symbol_list':
        setSymbolList(message.data); // Actualiza SymbolList usando la función de updates
        break;
      default:
        console.log('Mensaje desconocido:', message);
        break;
    }
  };

  // Función para enviar mensajes de suscripción
  const sendSubscription = (symbol, interval) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(
        JSON.stringify({ type: 'subscribe', symbol, interval })
      );
      console.log(`Suscripción enviada: Símbolo=${symbol}, Intervalo=${interval}`);
    } else {
      console.warn('WebSocket no está listo para enviar suscripciones.');
    }
  };

  useEffect(() => { 
    if (user) {
      connectWebSocket(); // Conectar WebSocket solo si el usuario está logueado
    }

    return () => {
      ws.current?.close(); // Cerrar conexión al desmontar
    };
  }, [user]);

  return (
    <WebSocketContext.Provider value={{ connected, sendSubscription }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);