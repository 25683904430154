import React, { createContext, useState, useMemo, useContext } from 'react';

const MarketDataStateContext = createContext();
const MarketDataSetContext = createContext();

export const MarketDataProvider = ({ children }) => {
  
  const [marketData, setMarketData] = useState({});

  const stateValue = useMemo(() => ({ marketData }), [marketData]);
  const setValue = useMemo(() => ({ setMarketData }), []);

  return (
    <MarketDataStateContext.Provider value={stateValue}>
      <MarketDataSetContext.Provider value={setValue}>
        {children}
      </MarketDataSetContext.Provider>
    </MarketDataStateContext.Provider>
  );
};

export const useMarketDataState = () => useContext(MarketDataStateContext);
export const useMarketDataSet = () => useContext(MarketDataSetContext);