import React, { useState } from 'react';
import { useModal } from '../context/ModalContext';
import { useSymbol, useSymbolUpdates } from '../context/SymbolContext';
import { useWebSocket } from '../context/WebSocketContext';
import GenericModal from './GenericModal';

const ModalManager = React.memo(() => {
  const {
    showSelectFutureModal,
    showCompareFuturesModal,
    showSavedChartsModal,
    showChangeColorsModal,
    handleCloseModal,
  } = useModal();

  const { symbolList } = useSymbol();
  const { setSymbolList } = useSymbolUpdates();
  const { sendSuscription } = useWebSocket();
  const [selectedFutures, setSelectedFutures] = useState([]);

  const modalsConfig = [
    {
      id: 'SelectFutureModal',
      title: 'Seleccionar Futuro',
      show: showSelectFutureModal,
      onClose: () => handleCloseModal('SelectFutureModal'),
      items: symbolList,
      onSelectItem: (future) => {
        setSymbolList([future]);
        sendSuscription(future);
        handleCloseModal('SelectFutureModal');
      },
    },
    {
      id: 'CompareFuturesModal',
      title: 'Comparar Futuros',
      show: showCompareFuturesModal,
      onClose: () => handleCloseModal('CompareFuturesModal'),
      items: symbolList,
      selectedItems: selectedFutures,
      onSelectItem: (future) => {
        if (!selectedFutures.includes(future)) {
          setSelectedFutures((prev) => [...prev, future]);
        }
      },
      onAction: () => {
        console.log('Comparar Futuros:', selectedFutures);
        handleCloseModal('CompareFuturesModal');
      },
      actionLabel: 'Comparar',
    },
    {
      id: 'SavedChartsModal',
      title: 'Gráficos Guardados',
      show: showSavedChartsModal,
      onClose: () => handleCloseModal('SavedChartsModal'),
      content: <p>Aquí puedes mostrar y administrar tus gráficos guardados.</p>,
    },
    {
      id: 'ChangeColorsModal',
      title: 'Cambiar Colores',
      show: showChangeColorsModal,
      onClose: () => handleCloseModal('ChangeColorsModal'),
      initialColors: {
        background: '#FFFFFF',
        upCandle: '#00FF00',
        downCandle: '#FF0000',
        volumeColor: '#0000FF',
        linesColors: '#808080',
        priceColor: '#FFD700',
        timeColor: '#A9A9A9',
      },
    },
  ];

  return (
    <>
      {modalsConfig.map(
        ({ id, ...modalProps }) =>
          modalProps.show && <GenericModal key={id} {...modalProps} />
      )}
    </>
  );
});

export default ModalManager;