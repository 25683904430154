import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext';

const UserProfileModal = ({ show, handleClose }) => {
  const { user, updateUser, alertMessage } = useAuth();
  const [nombre, setNombre] = useState(user?.name || '');
  const [apellido, setApellido] = useState(user?.lastname || '');
  const [email] = useState(user?.email || ''); // Campo no editable
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await updateUser(nombre, apellido);
      handleClose();
    } catch (err) {
      setError(alertMessage || 'Error al actualizar el usuario');
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered // Asegura que el modal esté centrado
    >
      <Modal.Header closeButton>
        <Modal.Title>Modificar Información del Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formEmail" className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              disabled // Campo no editable
            />
          </Form.Group>
          <Form.Group controlId="formNombre" className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formApellido" className="mb-3">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              type="text"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
              required
            />
          </Form.Group>
          {error && <p className="text-danger">{error}</p>}
          <div className="d-flex justify-content-center">
            <Button type="submit">Guardar Cambios</Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UserProfileModal;