import React, { useEffect, useRef } from 'react';
import { widget } from '../../charting_library';
import './index.css'


const TradingViewChart = () => {
  const chartContainerRef = useRef();

  useEffect(() => {
    const widgetOptions = {
      symbol: 'AAPL',
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed('https://demo_feed.tradingview.com'),
      interval: 'D',
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      locale: 'es',
      autosize: true,
      disabled_features: ['use_localstorage_for_settings'],
      theme: 'dark'
    };

    const tvWidget = new widget(widgetOptions);

    return () => {
      tvWidget.remove(); // Limpieza del widget al desmontar el componente
    };
  }, []);

  return (
    <div
      ref={chartContainerRef}
      className='chart-container'
    />
  );
};

export default TradingViewChart;