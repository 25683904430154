import React, { createContext, useContext, useState, useMemo } from 'react';

const SymbolContext = createContext();
const SymbolUpdateContext = createContext();

export const SymbolProvider = ({ children }) => {

    const [symbol, setSymbol] = useState("RFX20/DIC24");
    const [symbolList, setSymbolList] = useState([]);

    const stateValue = useMemo(() => ({ symbol, symbolList }), [symbol, symbolList]);
    const updateValue = useMemo(() => ({ setSymbol, setSymbolList }), []);

    return (
        <SymbolContext.Provider value={stateValue}>
            <SymbolUpdateContext.Provider value={updateValue}>
                {children}
            </SymbolUpdateContext.Provider>
        </SymbolContext.Provider>
    );
};

export const useSymbol = () => useContext(SymbolContext);
export const useSymbolUpdates = () => useContext(SymbolUpdateContext);