import React from 'react';
import { useMarketDataState } from '../context/MarketdataContext';

const MarketDataTable = () => {
  const { marketData } = useMarketDataState(); // Obtiene marketData directamente del contexto

  const marketRows = [
    ['Precio Ajuste', marketData?.settlement_price?.price ?? 'N/A'],
    ['Último Cierre', marketData?.close_price?.price ?? 'N/A'],
    ['Volumen Nominal', marketData?.nominal_volume ?? 'N/A'],
    ['Volumen Efectivo (ARS)', marketData?.effective_volume ?? 'N/A'],
    ['Interés Abierto', marketData?.open_interest?.size ?? 'N/A'],
    ['Volumen de Comercio', marketData?.trade_volume ?? 'N/A'],
    ['Precio de Apertura', marketData?.open_price ?? 'N/A'],
    ['Precio Más Alto', marketData?.high_price ?? 'N/A'],
    ['Último Precio', marketData?.last_price?.price ?? 'N/A'],
    ['Valor del Índice', marketData?.index_value ?? 'N/A'],
  ];

  return (
    <div className="table-market-data">
      <h3>Datos del Mercado</h3>
      <table>
        <tbody>
          {marketRows.map(([label, value], index) => (
            <tr key={index}>
              <td>{label}</td>
              <td>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Ofertas de Mercado</h3>
      <div className="table-offers">
        <table className="table-with-scroll">
          <thead>
            <tr>
              <th className="text-center">Compra</th>
              <th className="text-center">Venta</th>
            </tr>
          </thead>
          <tbody className="scrollable-tbody">
            <tr>
              <td>
                {marketData?.bids?.length ? (
                  <table>
                    <thead>
                      <tr>
                        <th className="text-center">Precio</th>
                        <th className="text-center">Tamaño</th>
                      </tr>
                    </thead>
                    <tbody>
                      {marketData.bids.map((item, index) => (
                        <tr key={index}>
                          <td>{item.price}</td>
                          <td>{item.size}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-data-message">Sin ofertas</div>
                )}
              </td>
              <td>
                {marketData?.offers?.length ? (
                  <table>
                    <thead>
                      <tr>
                        <th className="text-center">Precio</th>
                        <th className="text-center">Tamaño</th>
                      </tr>
                    </thead>
                    <tbody>
                      {marketData.offers.map((item, index) => (
                        <tr key={index}>
                          <td>{item.price}</td>
                          <td>{item.size}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="no-data-message">Sin ofertas</div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(MarketDataTable);