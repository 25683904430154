import React, { createContext, useState, useMemo, useContext } from 'react';

const TodayTradesStateContext = createContext();
const TodayTradesSetContext = createContext();

export const TodayTradesProvider = ({ children }) => {
  
  const [todayTrades, setTodayTrades] = useState([]);

  const stateValue = useMemo(() => ({ todayTrades }), [todayTrades]);
  const setValue = useMemo(() => ({ setTodayTrades }), []);

  return (
    <TodayTradesStateContext.Provider value={stateValue}>
      <TodayTradesSetContext.Provider value={setValue}>
        {children}
      </TodayTradesSetContext.Provider>
    </TodayTradesStateContext.Provider>
  );
};

export const useTodayTradesState = () => useContext(TodayTradesStateContext);
export const useTodayTradesSet = () => useContext(TodayTradesSetContext);