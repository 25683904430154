import React, { createContext, useContext, useMemo } from 'react';
import { openDB } from 'idb';
import { useInterval } from './IntervalContext';
import { useChart } from './ChartContext';

const IndexedDBContext = createContext();

export const IndexedDBProvider = ({ children }) => {
    const { selectedInterval } = useInterval(); // Obtener el intervalo seleccionado del contexto de Interval
    const { updateData } = useChart(); // Obtener la función updateData del contexto de Chart

    const openDatabase = async () => {
        return await openDB('TradingDataDB', 1, {
            upgrade(db) {
                const intervals = ['1min', '5min', '10min', '15min', '30min', '1h', '2h', '4h', '6h', '12h', '1d'];
                intervals.forEach((interval) => {
                    const storeName = `data_${interval}`;
                    if (!db.objectStoreNames.contains(storeName)) {
                        const store = db.createObjectStore(storeName, { keyPath: 'symbol' });
                        store.createIndex('symbol', 'symbol', { unique: false });
                    }
                });
            },
        });
    };

    const storeDataInIndexedDB = useMemo(() => {
        return async (storeName, symbol, data) => {
            try {
                // Validación de parámetros
                if (!storeName || !symbol) {
                    console.warn('storeName o symbol no proporcionados. Abortando almacenamiento.');
                    return;
                }

                // Validar que los datos existan y no estén vacíos
                if (!data || data.length === 0) {
                    console.warn(`No hay datos para almacenar en ${storeName} para el símbolo ${symbol}.`);
                    return;
                }

                const db = await openDatabase();
                const tx = db.transaction(storeName, 'readwrite');
                const store = tx.objectStore(storeName);

                // Limpiar los datos existentes
                await store.clear();
                console.log(`Datos en ${storeName} eliminados.`);

                // Insertar nuevos datos
                await store.put({ symbol, data });
                console.log(`Datos almacenados correctamente en ${storeName} para el símbolo ${symbol}.`);

                // Si el intervalo coincide con el seleccionado, actualiza el gráfico
                const intervalFromStoreName = storeName.split('_')[1];
                if (intervalFromStoreName === selectedInterval.value) {
                    console.log(`Actualizando gráfico para el intervalo ${selectedInterval.value}`);
                    updateData(data); // Actualizar el gráfico con los nuevos datos
                }

                await tx.done;
            } catch (error) {
                console.error(`Error almacenando datos en ${storeName} para el símbolo ${symbol}:`, error);
            }
        };
    }, [selectedInterval, updateData]);

    const getDataFromIndexedDB = useMemo(() => {
        return async (symbol, interval) => {
            const storeName = `data_${interval}`;

            try {
                // Validación de parámetros
                if (!symbol || !interval) {
                    console.warn('symbol o interval no proporcionados. Abortando consulta.');
                    return [];
                }

                const db = await openDatabase();
                const tx = db.transaction(storeName, 'readonly');
                const store = tx.objectStore(storeName);
                const index = store.index('symbol');

                const records = await index.getAll(symbol);

                if (!records || records.length === 0) {
                    console.warn(`No se encontraron datos en ${storeName} para el símbolo ${symbol}.`);
                    return [];
                }

                console.log(`Datos recuperados correctamente de ${storeName} para el símbolo ${symbol}.`);
                return records[0].data;
            } catch (error) {
                console.error(`Error recuperando datos de ${storeName} para el símbolo ${symbol}:`, error);
                return [];
            }
        };
    }, []);

    const value = useMemo(() => ({ storeDataInIndexedDB, getDataFromIndexedDB }), [storeDataInIndexedDB, getDataFromIndexedDB]);

    return <IndexedDBContext.Provider value={value}>{children}</IndexedDBContext.Provider>;
};

export const useIndexedDB = () => useContext(IndexedDBContext);